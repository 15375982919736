import { useContext } from 'react'
import SessionContext from 'components/session/SessionContext'
import strings from '../strings'
import { defaultLocale } from '../config'
import { getCookie } from 'functions'

export default function useTranslation() {
    const { session } = useContext(SessionContext);
    const lang = session.lang || getCookie('lang') || 'en'
    const t = key =>
        strings[lang] && strings[lang][key] ||
        strings[defaultLocale][key] || key || ''
    return { lang, t }
}