import { getCookie } from 'functions'
import Link from 'next/link'
import { useContext } from 'react'
import SessionContext from './session/SessionContext'

export default function LinkWithTranslation(props) {
    let { session } = useContext(SessionContext)
    let lang = session.lang || getCookie('lang') || 'en';

    let { href = '', as = '', children, ...rest } = props;
    href = href === '/' ? '' : href

    var _href = lang ? '/[lang]' : ''
    var _as = lang ? `/${lang}` : ''

    var wrapperProps = {
        href: `${_href}${href}`,
        as: as ? `${_as}${as}` : `${_as}${href}`
    }

    return <Link {...wrapperProps} {...rest}>{children}</Link>
}