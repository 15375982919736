import { getCookie } from 'functions'
import { defaultLocale } from './config'
import { isLocale } from './types'

export default function getLocale(req, query) {
    var lang = query && query.lang || req && req.query && req.query.lang || getCookie('lang', req)
    if (lang) return lang

    var [browserSetting] = process.browser ? navigator.language.split('-') : ''
    if (isLocale(browserSetting)) return browserSetting

    return defaultLocale
}