import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
    footer: {
        width: '100%',
        minHeight: 400,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        alignContent: 'center',
        backgroundColor: '#344450',
        color: '#FFFFFF',
        lineHeight: '1.5em',
        [theme.breakpoints.down('768')]: {
            padding: '20px 0px 0px 0px'
        }
    },
    footerContentLeft: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.up('1024')]: {
            width: '80%',
            flexDirection: 'row',
            justifyContent: 'space-around'
        },
        alignItems: 'center',
        alignContent: 'center',
    },
    footerContentRight: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.up('1024')]: {
            width: '80%',
            flexDirection: 'row-reverse',
            justifyContent: 'space-around'
        },
        alignItems: 'center',
        alignContent: 'center',
    },
    bottomFooter1: {
        display: 'block',
        [theme.breakpoints.down('768')]: {
            display: 'none'
        }
    },
    bottomFooter2: {
        display: 'none',
        [theme.breakpoints.down('768')]: {
            display: 'block',
            marginTop: 30,
            width: '60%',
            zIndex: 2
        }
    },
    socialmedia: {
        width: '1em !important',
        margin: '5%'
    },
    copyright: {
        fontSize: '14px !important',
        color: '#e4e4e4 !important',
        [theme.breakpoints.down('1024')]: {
            paddingBottom: 60,
            fontSize: '14px !important'
        }
    },
    flexListsLeft: {
        width: '100%',
        marginTop: 30,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        [theme.breakpoints.up('1024')]: {
            marginTop: 80,
            width: '50%',
            lineHeight: '2.3em'
        },
        zIndex: 1,
        [theme.breakpoints.down('768')]: {
            flexWrap: 'wrap',
            justifyContent: 'flex-start',
            padding: '5px 0px 0px 10%'
        }
    },
    flexListsRight: {
        width: '100%',
        marginTop: 30,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        [theme.breakpoints.up('1024')]: {
            marginTop: 80,
            width: '50%',
            lineHeight: '2.3em'
        },
        zIndex: 1,
        [theme.breakpoints.down('768')]: {
            flexWrap: 'wrap',
            justifyContent: 'flex-start',
            padding: '5px 10% 0px 0px'
        }
    },
    list: {
        width: 'fit-content',
        '& ul': {
            listStyle: 'none',
            display: 'inline',
            fontSize: '14px !important',
            paddingInlineStart: '0px !important'
        },
        [theme.breakpoints.down('768')]: {
            '&:nth-child(1)': {
                width: '50%'
            },
            '&:nth-child(2)': {
                width: '50%'
            },
            '&:nth-child(3) div': {
                width: '50%'
            },
            '&:nth-child(3)': {
                marginTop: 30,
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-end',
                alignContent: 'flex-end',
                justifyContent: 'flex-start'
            }
        }
    },
    listTitle: {
        fontWeight: 'bold !important',
        fontsize: '20px !important',
        height: 48 
    },
    shapeLeft: {
        display: 'flex',
        flexDirection: 'column',
        alignSelf: 'flex-end',
        justifyContent: 'flex-end',
        position: 'absolute',
        right: 0,
        zIndex: 0,
        width: '20%',
        [theme.breakpoints.down('768')]: {
            width: '25%'
        },
        [theme.breakpoints.up('1042')]: {
            width: '15%'
        },
        [theme.breakpoints.up('1800')]: {
            display: 'none'
        }
    },
    shapeRight: {
        display: 'flex',
        flexDirection: 'column',
        alignSelf: 'flex-end',
        justifyContent: 'flex-end',
        position: 'absolute',
        left: 0,
        zIndex: 0,
        width: '20%',
        [theme.breakpoints.down('768')]: {
            width: '25%'
        },
        [theme.breakpoints.up('1042')]: {
            width: '15%'
        },
        [theme.breakpoints.up('1800')]: {
            display: 'none'
        }
    },
    logoFooter: {
        width: '80%',
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.up('1024')]: {
            width: '35%',
            marginBottom: 40
        },
        [theme.breakpoints.down('1024')]: {
            '& img': {
                width: '60%',
            },
            marginTop:'5%',

        }
    }
}))

export default useStyles