/**
 * @param {Object} opts 
 * @return {Promise} 
 */
export async function fetchFromAPI({ api, reqBody, header, method = 'POST' }) {
    let data = {}
    let url = `/api/${api}`

    let requests = { method }

    if (reqBody) requests['body'] = JSON.stringify(reqBody);
    if (header) requests['headers'] = header;

    let response = await fetch(url, requests);
    data = await response.json();

    return data;
}