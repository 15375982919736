import Footer from 'components/Footer'
import Header from 'components/Header'
import { Fragment } from 'react'

export default function Layout({ children }) {
    return (
        <Fragment>
            <Header />
            {children}
            <Footer />
        </Fragment>
    )
}