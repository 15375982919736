import { getCookie } from 'functions';
import Router from 'next/router';

export function handleRouter(props) {
    let lang = getCookie('lang') || 'en';

    let { href, as } = props;
    href = href === '/' ? '' : href;

    var _href = lang ? '/[lang]' : '';
    var _as = lang ? `/${lang}` : '';

    href = `${_href}${href}`;
    as = as ? `${_as}${as}` : `${_as}${href}`;

    Router.push(href, as);
}