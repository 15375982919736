import cookie from 'js-cookie'

export function setCookie(key, value) {
    if (process.browser) {
        cookie.set(key, value, { expires: 30 })
    }
}

export function removeCookie(key) {
    if (process.browser) {
        cookie.remove(key)
    }
}

export function getCookie(key, req) {
    return process.browser
        ? getCookieFromBrowser(key)
        : getCookieFromServer(key, req)
}

function getCookieFromBrowser(key) {
    return cookie.get(key)
}

function getCookieFromServer(key, req) {
    let cookies = req && req.cookies || {};
    if (cookies[key]) return cookies[key]

    if (!(req && req.headers && req.headers.cookie)) return undefined

    const rawCookie = req.headers.cookie.split(';').find(c => c.trim().startsWith(`${key}=`))
    if (!rawCookie) return undefined

    return rawCookie.split('=')[1]
}