export function formatMoney(n) {
    let c = isNaN((c = Math.abs(c))) ? 2 : c
    let d = d == undefined ? '.' : d
    let t = t == undefined ? ',' : t
    let s = n < 0 ? '-' : ''
    let i = parseInt((n = Math.abs(+n || 0).toFixed(c))) + ''
    let j = (j = i.length) > 3 ? j % 3 : 0;

    let result = s +
        (j ? i.substr(0, j) + t : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + t) +
        (c ? d + Math.abs(n - i).toFixed(c).slice(2) : '')

    return result
};