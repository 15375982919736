import { fetchFromAPI, fetchPost, getCookie, handleRouter, removeCookie } from 'functions'
import { useEffect, useState } from 'react'
import SessionContext from './SessionContext'

export default function SessionProvider({ children, lang }) {

    const [session, updateSession] = useState({
        user: {
            _id: '',
            authToken: ''
        },
        lang: lang || 'en'
    });

    function setSession(nextSession) {
        updateSession(prevSession => ({
            ...prevSession,
            ...nextSession
        }))
    }

    async function signin({ email, password }) {
        let response = {};
        let reqBody = { email, password, isEmailLogin: true };
        window ?
            response = await fetchFromAPI({ api: 'auth/signin', reqBody }) :
            response = await fetchPost({ apiName: 'loginsignup', reqBody });
        return response;
    }

    async function signup(reqBody) {
        let response = {};
        window ?
            response = await fetchFromAPI({ api: 'auth/signup', reqBody }) :
            response = await fetchPost({ apiName: 'requestToJoin', reqBody });
        return response;
    }

    async function signout() {
        let header = { _id, authToken };
        window ?
            await fetchFromAPI({ api: 'auth/signout', header }) :
            await fetchPost({ apiName: 'logout', header });

        removeCookie('_id');
        removeCookie('authToken');
        setSession({ user: {} });
        handleRouter({ href: '/', as: '/' });
    }

    let _id = getCookie('_id');
    let authToken = getCookie('authToken');

    useEffect(() => {

        async function initializeUser() {
            if (!_id || !authToken) return;

            let header = { _id, authToken }
            let response = {};
            window ?
                response = await fetchFromAPI({ api: 'auth/initializeUser', header }) :
                response = await fetchPost({ apiName: 'getUserData', header });

            if (response.status == 'success' && response.response && response.response._id) {
                let user = response.response;
                setSession({ user })
            } else {
                setSession({ user: {} });
            }
        }
        if (!session.user?._id) initializeUser();
    }, [session.user._id]); //eslint-disable-line react-hooks/exhaustive-deps

    let context = { session, actions: { signin, signout, signup, setSession } }

    return (
        <SessionContext.Provider value={context}>
            {children}
        </SessionContext.Provider>
    )
}