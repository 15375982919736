let ACCESS_TOKEN = process.env.ACCESS_TOKEN;
let HOST_URL = process.env.HOST_URL;

/**
 * Used to make it easy to fetch from backend
 * @param {Object} args
 * @param {String} args.apiName
 * @param {Object} [args.reqBody]
 * @param {Object} [args.header]
 * @returns 
 */
export async function fetchPost({ apiName, reqBody, header }) {
    let data = {};
    let url = HOST_URL + apiName;
    let body = reqBody || {};
    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'AUTHORIZATION': ACCESS_TOKEN
    }

    if (header && header._id && header.authToken) {
        headers['X-User-Id'] = header._id;
        headers['X-Auth-Token'] = header.authToken;
    }

    let requests = {
        method: 'POST',
        headers,
        body: JSON.stringify(body)
    }

    try {
        let response = await fetch(url, requests);
        data = await response.json();
        return data;
    }
    catch (e) {
        return {
            status: 'error',
            error: e,
            response: e,
            responseType: e
        }
    }
}
