import { getCookie } from "functions";
import Router from "next/router";
import { isLocale } from "translations/types";

export const redirect = (ctx, lang = 'en', options = {}) => {

    var { allow = false, redirectTo = {} } = options || {}

    if (isLocale(lang) && !allow) return false;
    if (!isLocale(lang)) return handleRedirect(ctx, { href: '/[lang]', as: `/${lang || 'en'}` });

    var _id = getCookie('_id', ctx?.req);
    var authToken = getCookie('authToken', ctx?.req);
    var isGuestByCookie = getCookie("isGuest", ctx?.req);
    var isGuest = isGuestByCookie || !_id || !authToken
    var status = []

    // if (isGuestByCookie) status.push('guestByCookie')
    if (isGuest) status.push('guest')
    else status.push('!guest')

    var canAccessPage = allow.filter(value => status.includes(value)).length > 0

    if (canAccessPage) return false;

    var redirectUrl = createRedirectUrl(redirectTo, lang)
    return handleRedirect(ctx, redirectUrl)
}

const createRedirectUrl = ({ href, as }, lang) => {

    href = href === '/' ? '' : href
    var pre_href = lang ? '/[lang]' : ''
    var pre_as = lang ? `/${lang}` : ''

    var redirectUrl = {
        href: `${pre_href}${href}`,
        as: as ? `${pre_as}${as}` : `${pre_as}${href}`
    }

    return redirectUrl
}

const handleRedirect = ({ req, res }, { href, as }) => {
    if (req) {
        res.writeHead(302, { Location: as });
        res.end();
    } else {
        Router.push(href, as);
    }

    return true;
}