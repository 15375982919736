/**
 * Check Array
 * @param {*} arr
 */
export function isArray(arr) {
    if (arr && Array.isArray(arr) && arr.length > 0) return true
    else return false
}

/**
 * Capitalize First Letter
 * @param {*} String
 */
export function capitalize(str) {
    let string = str.charAt(0).toUpperCase() + str.slice(1);
    return string;
}