import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
    headerTopLeft: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end',
        color: '#e4e4e4',
        backgroundColor: '#344450',
        padding: 10,
        paddingLeft: 50,
        [theme.breakpoints.down('900')]: {
            display: 'none'
        }
    },
    headerTopRight: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end',
        color: '#e4e4e4',
        backgroundColor: '#344450',
        padding: 10,
        paddingRight: 30,
        [theme.breakpoints.down('900')]: {
            display: 'none'
        }
    },
    span: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        fontSize: 16,
        marginRight: 20,
        cursor: 'pointer',
        [theme.breakpoints.down('900')]: {
            fontSize: 22,
            padding: 0,
            marginRight: 6,
        }
    },
    span2: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        fontSize: 24,
        cursor: 'pointer',
        padding: 3,
    },
    select: {
        border: 'none',
        backgroundColor: '#344450',
        color: '#ffffff',
        [theme.breakpoints.down('900')]: {
            display: 'none'
        },
        '& .MuiSelect-icon': {
            color: '#FFFFFF'
        }
    },
    avatar: {
        fontSize: 10,
        width: 30,
        height: 30,
        marginRight: 20,
        cursor: 'pointer',
        backgroundColor: '#FFFFFF',
        [theme.breakpoints.down('900')]: {
            width: 25,
            height: 25,
            marginRight: 5
        }
    },
    navbar: {
        display: 'flex',
        overflow: 'hidden',
        [theme.breakpoints.up('900')]: {
            flexDirection: 'row',
            justifyContent: 'space-around',
            backgroundColor: '#e4e4e4',
            padding: '20px 0px',
        },
        [theme.breakpoints.down('900')]: {
            flexDirection: 'row-reverse',
            justifyContent: 'space-between',
            backgroundColor: '#344450',
            padding: '10px 10px',
        },
        alignItems: 'center',
    },
    subHeader: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end',
        color: '#e4e4e4',
        width: 60,
        [theme.breakpoints.up('900')]: {
            display: 'none'
        }
    },
    flexRow: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        alignContent: 'center',
        cursor: 'pointer',
        [theme.breakpoints.down('900')]: {
            textAlign: 'center',
            width: 60,
        }
    },
    logoText: {
        fontSize: '100%',
        fontWeight: 'bold',
        color: '#344450',
        [theme.breakpoints.down('1024')]: {
            fontSize: 14
        },
        [theme.breakpoints.down('900')]: {
            display: 'none'
        }
    },
    logoLeft: {
        width: 75,
        height: 75,
        marginRight: 18,
        [theme.breakpoints.down('900')]: {
            width: 45,
            height: 45
        }
    },
    logoRight: {
        width: 75,
        height: 75,
        marginLeft: 18,
        [theme.breakpoints.down('900')]: {
            width: 45,
            height: 45
        }
    },
    navmenuLeft: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        zIndex: 100,
        [theme.breakpoints.down('900')]: {
            position: 'absolute',
            flexDirection: 'column',
            alignItems: 'flex-start',
            top: 60,
            left: 0,
            backgroundColor: '#344450',
        }
    },
    navmenuRight: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        zIndex: 100,
        [theme.breakpoints.down('900')]: {
            position: 'absolute',
            flexDirection: 'column',
            alignItems: 'flex-start',
            top: 60,
            right: 0,
            backgroundColor: '#344450',
        }
    },
    navlink: {
        border: 'none',
        outline: 'none',
        color: '#344450',
        padding: '43px 14px',
        width: 'auto',
        backgroundColor: 'inherit',
        margin: 0,
        fontSize: 16,
        fontWeight: 'bold',
        '&:hover': {
            borderBottom: '6px solid #f5b750'
        },
        [theme.breakpoints.down('1024')]: {
            fontSize: 14
        },
        [theme.breakpoints.down('900')]: {
            color: '#e4e4e4',
            padding: '3px 10px',
            '&:hover': {
                borderBottom: '2px solid #f5b750'
            },
        }
    },
    navlinkList: {
        cursor: 'default',
        border: 'none',
        outline: 'none',
        color: '#344450',
        padding: '43px 14px',
        width: 'auto',
        backgroundColor: 'inherit',
        margin: 0,
        fontSize: 16,
        fontWeight: 'bold',
        '&:hover': {
            borderBottom: '6px solid #f5b750'
        },
        [theme.breakpoints.down('1024')]: {
            fontSize: 14
        },
        [theme.breakpoints.down('900')]: {
            color: '#e4e4e4',
            padding: '3px 10px',
            '&:hover': {
                borderBottom: '2px solid #f5b750'
            },
        }
    },
    dropdownEN: {
        '& a': {
            float: 'none',
            color: '#e4e4e4',
            textDecoration: 'none',
            display: 'block',
            padding: '10px 16px',
            '&:hover': {
                color: '#f5b750'
            }
        },
        [theme.breakpoints.up('900')]: {
            display: 'none',
            position: 'absolute',
            marginTop: 48,
            backgroundColor: '#344450',
            minWidth: 160,
            zIndex: 100,
        },
        [theme.breakpoints.down('900')]: {
            fontSize: 12,
            margin: 0,
            display: 'none',
            position: 'relative',
            padding: '5px 0px 0px 0px'
        }
    },
    dropdownAR: {
        '& a': {
            float: 'none',
            color: '#e4e4e4',
            textDecoration: 'none',
            display: 'block',
            padding: '10px 16px',
            '&:hover': {
                color: '#f5b750'
            }
        },
        [theme.breakpoints.up('900')]: {
            display: 'none',
            position: 'absolute',
            marginTop: 43,
            backgroundColor: '#344450',
            minWidth: 160,
            zIndex: 100,
        },
        [theme.breakpoints.down('900')]: {
            fontSize: 12,
            margin: 0,
            display: 'none',
            position: 'relative',
            padding: '5px 0px 0px 0px'
        }
    },
    divNavLink: {
        '&:nth-child(1):hover': {
            '& .dropdown1': {
                display: 'block'
            }
        },
        '&:nth-child(2):hover': {
            '& .dropdown2': {
                display: 'block'
            }
        },
        [theme.breakpoints.down('900')]: {
            fontSize: 12,
            padding: '15px 5px'
        }
    },
    icons: {
        display: 'none',
        width: 60,
        color: '#E4E4E4',
        zIndex: 50,
        [theme.breakpoints.down('900')]: {
            display: 'block'
        }
    },
    logoutDivLink: {
        display: 'none',
        color: '#E4E4E4',
        fontSize: 12,
        padding: '15px 15px',
        [theme.breakpoints.down('900')]: {
            display: 'block',
            display: 'flex',
            alignItems: 'center'
        }
    },
    setClose: {
        display: 'none',
        width: '100%',
        height: '100vh',
        position: 'absolute',
        [theme.breakpoints.down('900')]: {
            display: 'block'
        }
    }
}));

export default useStyles