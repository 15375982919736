import { Box } from '@material-ui/core'
import { Email, Facebook, Phone, Twitter } from '@material-ui/icons'
import Link from 'components/Link'
import { fetchFromAPI } from 'functions'
import Image from 'next/image'
import LinkNext from 'next/link'
import { useContext, useEffect, useState } from 'react'
import useStyles from 'styles/Footer.Style'
import useTranslation from 'translations/hooks/useTranslation'
import SessionContext from './session/SessionContext'

export default function Footer() {
    const classes = useStyles();
    const { t } = useTranslation();

    let { session: { lang } } = useContext(SessionContext);
    let isAr = lang === 'ar';

    const [state, updateState] = useState({
        facebook: '',
        twitter: '',
        supportPhoneNumber: '',
        supportEmail: ''
    });

    function setState(nextState) {
        updateState(prevState => ({
            ...prevState,
            ...nextState
        }))
    }

    useEffect(() => {
        function fetchData() {
            fetchFromAPI({ api: 'footer' })
                .then(response => {
                    let result = response.response;
                    let { facebook, twitter, supportPhoneNumber, supportEmail } = result;
                    setState({
                        facebook,
                        twitter,
                        supportPhoneNumber,
                        supportEmail
                    })
                })
        }
        fetchData();
    }, [])

    return (
        <Box className={classes.footer}>
            <Box className={isAr ? classes.footerContentRight : classes.footerContentLeft}>
                <Box className={classes.logoFooter}>
                    <Image
                        src='/OEAlogo.png'
                        alt='OEA logo'
                        layout='responsive'
                        width={'100%'}
                        height={'30%'}
                    />
                    <Box className={classes.bottomFooter1}>
                        <Box>
                            <LinkNext href={`${state.facebook}`}>
                                <a target='_blank' aria-label="Facebook">
                                    <Facebook className={classes.socialmedia} />
                                </a>
                            </LinkNext>
                            <LinkNext href={`${state.twitter}`}>
                                <a target='_blank' aria-label="Twitter">
                                    <Twitter className={classes.socialmedia} />
                                </a>
                            </LinkNext>
                            <LinkNext href={`tel:${state.supportPhoneNumber}`}>
                                <a target='_blank' aria-label="Phone Number">
                                    <Phone className={classes.socialmedia} />
                                </a>
                            </LinkNext>
                            <LinkNext href={`mailto:${state.supportEmail}`}>
                                <a target='_blank' aria-label="Email">
                                    <Email className={classes.socialmedia} />
                                </a>
                            </LinkNext>
                        </Box>
                        <Box className={classes.copyright}>
                            {t('footerCopyrights')}
                        </Box>
                    </Box>
                </Box>
                <Box className={isAr ? classes.flexListsRight : classes.flexListsLeft}>
                    <Box className={classes.list}>
                        <Box className={classes.listTitle}>
                            {t('contactUs')}
                        </Box>
                        <ul>
                            <li style={{ height: 48 }}>
                                <Link href='/contactus' as='/contactus'>
                                    <a>{t('contactOea')}</a>
                                </Link>
                            </li>
                            <li style={{ height: 48 }}>
                                <Link href='/contactus/laboratory' as='/contactus/laboratory'>
                                    <a>{t('contactLaboratory')}</a>
                                </Link>
                            </li>
                            <li style={{ height: 48 }}>
                                <Link href='/contactus/trainingcenter' as='/contactus/trainingcenter'>
                                    <a>{t('contactTrainingCenter')}</a>
                                </Link>
                            </li>
                        </ul>
                    </Box>
                    <Box className={classes.list}>
                        <Box className={classes.listTitle}>
                            {t('visitUs')}
                        </Box>
                        <ul>
                            <li style={{ height: 48 }}>
                                <Link href='/visitus/oea' as='/visitus/oea'>
                                    <a>{t('visitOea')}</a>
                                </Link>
                            </li>
                            <li style={{ height: 48 }}>
                                <Link href='/visitus/laboratory' as='/visitus/laboratory'>
                                    <a>{t('visitLaboratory')}</a>
                                </Link>
                            </li>
                            <li style={{ height: 48 }}>
                                <Link href='/visitus/trainingcenter' as='/visitus/trainingcenter'>
                                    <a>{t('visitTrainingCenter')}</a>
                                </Link>
                            </li>
                        </ul>
                    </Box>
                    <Box className={classes.list}>
                        <Box>
                            <Box className={classes.listTitle}>
                                {t('aboutUs')}
                            </Box>
                            <ul>
                                <li style={{ height: 48 }}>
                                    <Link href='/news/[...params]' as='/news/1'>
                                        <a>{t('latestFeeds')}</a>
                                    </Link>
                                </li>
                                <li style={{ height: 48 }}>
                                    <Link href='/events/[...params]' as='/events/1'>
                                        <a>{t('upcomingEvents')}</a>
                                    </Link>
                                </li>
                                <li style={{ height: 48 }}>
                                    <Link href='/gallery/[page]' as='/gallery/1'>
                                        <a>{t('gallery')}</a>
                                    </Link>
                                </li>
                            </ul>
                        </Box>
                        <Box>
                            <ul>
                                <li style={{ height: 48 }}>
                                    <Link href='/privacy-policy' as='/privacy-policy'>
                                        <a style={{ height: 48 }}>{t('privacyPolicy')}</a>
                                    </Link>
                                </li>
                                <li style={{ height: 48 }}>
                                    <Link href='/faq' as='/faq'>
                                        <a style={{ height: 48 }}>{t('termOfUse')}</a>
                                    </Link>
                                </li>
                                <li style={{ height: 48 }}>&nbsp;</li>
                            </ul>
                        </Box>
                    </Box>
                </Box>
                <Box className={classes.bottomFooter2}>
                    <Box>
                        <LinkNext href={`${state.facebook}`}>
                            <a target='_blank' aria-label="Facebook">
                                <Facebook className={classes.socialmedia} />
                            </a>
                        </LinkNext>
                        <LinkNext href={`${state.twitter}`}>
                            <a target='_blank' aria-label="Twitter">
                                <Twitter className={classes.socialmedia} />
                            </a>
                        </LinkNext>
                        <LinkNext href={`tel:${state.supportPhoneNumber}`}>
                            <a target='_blank' aria-label="Phone Number">
                                <Phone className={classes.socialmedia} />
                            </a>
                        </LinkNext>
                        <LinkNext href={`mailto:${state.supportEmail}`}>
                            <a target='_blank' aria-label="Email">
                                <Email className={classes.socialmedia} />
                            </a>
                        </LinkNext>
                    </Box>
                    <Box className={classes.copyright}>
                        {t('footerCopyrights')}
                    </Box>
                </Box>
            </Box>
            <Box className={isAr ? classes.shapeLeft : classes.shapeLeft}>
                <Image
                    src='/shape2.png'
                    alt='shape'
                    width={150}
                    height={250}
                />
            </Box>
        </Box>
    )
}