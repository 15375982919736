import Layout from 'components/Layout/Layout'
import RTL from 'components/RTL'
import SessionProvider from 'components/session/SessionProvider'
import { redirect, setCookie } from 'functions'
import 'public/fonts/JannaLt-arabicfont/main.css'
import 'public/fonts/Montserrat/main.css'
import { Fragment, useEffect } from 'react'
import 'styles/globals.css'
import getLocale from 'translations/getLocale'

export default function MyApp({ Component, pageProps, lang, dir }) {

  useEffect(() => {
    setCookie('lang', lang)
    redirect({}, lang, Component.options || {})
  }, [lang])

  return (
    <SessionProvider lang={lang}>
      <Fragment>
        <RTL dir={dir}>
          <Layout>
            <Component {...pageProps} />
          </Layout>
        </RTL>
      </Fragment>
    </SessionProvider>
  )
}

MyApp.getInitialProps = async function ({ Component, ctx }) {
  const lang = getLocale(ctx.req, ctx.query);
  const dir = lang == 'ar' ? 'rtl' : 'ltr';

  const appShouldRedirect = redirect(ctx, lang, Component.options || {});
  if (appShouldRedirect) return {};

  const pageProps = Component.getInitialProps
    ? await Component.getInitialProps(ctx)
    : {};

  return { pageProps, lang, dir };
};