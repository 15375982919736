import { translateNumber } from "./translateNumber";

var DateFormatter = {
    monthNamesEN: [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
    ],
    monthNamesAR: ['كانون الثاني', 'شباط', 'آذار', 'نيسان', 'أيار', 'حزيران',
        'تموز', 'آب', 'أيلول', 'تشرين الأول', 'تشرين الثاني', 'كانون الأول'
    ],
    dayNamesEN: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
    dayNamesAR: ['الأحَد', 'الإثنين', 'الثلاثاء', 'الأربعاء', 'الخميس', 'الجمعة', 'السبت'],

    formatDate: function (date, format, isAr) {
        var self = this;

        let dayNames = isAr ? self.dayNamesAR : self.dayNamesEN;
        let monthNames = isAr ? self.monthNamesAR : self.monthNamesEN;

        format = self.getProperDigits(format, /d+/gi, date.getDate());
        format = self.getProperDigits(format, /M+/g, date.getMonth() + 1);
        format = format.replace(/y+/gi, function (y) {
            var len = y.length;
            var year = date.getFullYear();
            if (len == 2)
                return (year + '').slice(-2);
            else if (len == 4)
                return year;
            return y;
        })
        format = self.getProperDigits(format, /H+/g, date.getHours());
        format = self.getProperDigits(format, /h+/g, self.getHours12(date.getHours()));
        format = self.getProperDigits(format, /m+/g, date.getMinutes());
        format = self.getProperDigits(format, /s+/gi, date.getSeconds());
        format = format.replace(/a/ig, function (a) {
            var amPm = self.getAmPm(date.getHours(), isAr)
            if (a === 'A')
                return amPm.toUpperCase();
            return amPm;
        })
        format = self.getFullOr3Letters(format, /d+/gi, dayNames, date.getDay(), isAr)
        format = self.getFullOr3Letters(format, /M+/g, monthNames, date.getMonth(), isAr)
        return format;
    },
    getProperDigits: function (format, regex, value) {
        return format.replace(regex, function (m) {
            var length = m.length;
            if (length == 1)
                return value;
            else if (length == 2)
                return ('0' + value).slice(-2);
            return m;
        })
    },
    getHours12: function (hours) {
        return (hours + 24) % 12 || 12;
    },
    getAmPm: function (hours, isAr) {
        return hours >= 12 ? isAr ? 'م' : 'pm' : isAr ? 'ص' : 'am';
    },
    getFullOr3Letters: function (format, regex, nameArray, value, isAr) {
        return format.replace(regex, function (s) {
            var len = s.length;
            if (len == 3) {
                let m;
                if (nameArray[value] == 'كانون الأول')
                    m = 'ك1'
                else if (nameArray[value] == 'كانون الثاني')
                    m = 'ك2'
                else if (nameArray[value] == 'تشرين الأول')
                    m = 'ت1'
                else if (nameArray[value] == 'تشرين الثاني')
                    m = 'ت2'
                else m = isAr ? nameArray[value] : nameArray[value].substr(0, 3);
                return m;
            }
            else if (len == 4)
                return nameArray[value];
            return s;
        })
    }
}

export function dateFormatter(date, string, isAr) {
    if (!date || date == undefined || date == '') return ''
    let result = DateFormatter.formatDate(new Date(date), string, isAr);
    if (isAr) result = translateNumber(result);
    return result;
}